<template>
    <div class="content-box">
        <div class="alert alert-warning">
            <BootstrapIcon icon="exclamation" size="2x"/>
            {{ $t('messages.receiptWarning') }}
        </div>
        <div v-if="receipt.closedBy" class="alert alert-info">
            <BootstrapIcon icon="info" size="2x"/>
            {{ $t('messages.itemClosed') }}
        </div>
        <b-form @submit.prevent="onSubmit" :class="{ disabled: receipt.closedBy }">
            <b-row>
                <b-col cols="12">
                    <b-form-group
                        v-if="'closedBy' in receipt"
                        class="row"
                        label="Closed by"
                        label-for="h-status"
                        label-cols-md="2"
                        content-cols-lg="4"
                    >
                        <b-form-input disabled
                                      :model-value="receipt.closedBy.givenName + ' ' + receipt.closedBy.familyName"
                                      id="closed" type="text"/>
                    </b-form-group>
                    <b-form-group
                        v-if="'changedStatusBy' in receipt"
                        class="row"
                        label="Changed status by"
                        label-for="h-status"
                        label-cols-md="2"
                        content-cols-lg="4"
                    >
                        <b-form-input disabled
                                      :model-value="receipt.changedStatusBy.givenName + ' ' + receipt.changedStatusBy.familyName"
                                      id="closed" type="text"/>
                    </b-form-group>
                    <b-form-group
                        v-if="'id' in receipt && !isOwner()"
                        class="row"
                        :label="$t('forms.status')"
                        label-for="h-status"
                        label-cols-md="2"
                        content-cols-lg="4"
                    >
                        <v-select
                            id="h-status"
                            v-model="statusSelect"
                            :options="statuses"
                        />
                    </b-form-group>

                    <b-form-group
                        :class="{ row, error: v$.branchSelect.$errors.length }"
                        :label="$t('forms.branch')"
                        label-for="h-branch"
                        label-cols-md="2"
                        content-cols-lg="4"
                    >
                        <v-select
                            id="h-branch"
                            v-model="branchSelect"
                            :options="branches"
                        />
                        <div
                            class="input-errors"
                            v-for="error of v$.branchSelect.$errors"
                            :key="error.$uid"
                        >
                            <small class="text-danger">{{ error.$message }}</small>
                        </div>
                    </b-form-group>
                    <b-form-group
                        :class="{ row, error: v$.receipt.date.$errors.length }"
                        :label="$t('forms.date')"
                        label-for="h-date"
                        label-cols-md="2"
                        content-cols-lg="4"
                    >
                        <datepicker
                            id="date"
                            v-model="receipt.date"
                            class="form-input"
                            format="dd.MM.yyyy"
                            :enableTimePicker="false"
                        />
                        <div
                            class="input-errors"
                            v-for="error of v$.receipt.date.$errors"
                            :key="error.$uid"
                        >
                            <small class="text-danger">{{ error.$message }}</small>
                        </div>
                    </b-form-group>
                    <b-form-group
                        :class="{ row, error: v$.stationSelect.$errors.length }"
                        :label="$t('forms.station')"
                        label-for="h-station"
                        label-cols-md="2"
                        content-cols-lg="4"
                    >
                        <v-select
                            id="h-station"
                            v-model="stationSelect"
                            :options="stations"
                        />
                        <div
                            class="input-errors"
                            v-for="error of v$.stationSelect.$errors"
                            :key="error.$uid"
                        >
                            <small class="text-danger">{{ error.$message }}</small>
                        </div>
                    </b-form-group>
                    <b-form-group
                        :class="{ row, error: v$.categorySelect.$errors.length }"
                        :label="$t('forms.category')"
                        label-for="h-category"
                        label-cols-md="2"
                        content-cols-lg="4"
                    >
                        <v-select
                            id="h-category"
                            v-model="categorySelect"
                            :options="categories"
                        />
                        <div
                            class="input-errors"
                            v-for="error of v$.categorySelect.$errors"
                            :key="error.$uid"
                        >
                            <small class="text-danger">{{ error.$message }}</small>
                        </div>
                    </b-form-group>
                    <b-form-group
                        class="row"
                        :label="$t('forms.description')"
                        label-for="description"
                        label-cols-md="2"
                        content-cols-lg="4"
                    >
                        <b-form-textarea v-model="receipt.description" id="description" type="number"/>

                    </b-form-group>
                    <b-form-group
                        :class="{ row, error: v$.receipt.amount.$errors.length }"
                        :label="$t('forms.amount')"
                        label-for="amount"
                        label-cols-md="2"
                        content-cols-lg="4"
                    >
                        <b-form-input v-model="receipt.amount" id="description" type="text"/>
                        <div
                            class="input-errors"
                            v-for="error of v$.receipt.amount.$errors"
                            :key="error.$uid"
                        >
                            <small class="text-danger">{{ error.$message }}</small>
                        </div>
                    </b-form-group>
                    <b-form-group
                        :class="{ row, error: v$.currencySelect.$errors.length }"
                        :label="$t('forms.currency')"
                        label-for="h-currency"
                        label-cols-md="2"
                        content-cols-lg="4"
                    >
                        <v-select
                            id="h-currency"
                            v-model="currencySelect"
                            :options="currencies"
                        />
                        <div
                            class="input-errors"
                            v-for="error of v$.currencySelect.$errors"
                            :key="error.$uid"
                        >
                            <small class="text-danger">{{ error.$message }}</small>
                        </div>
                    </b-form-group>
                    <b-form-group
                        class="row"
                        :label="$t('forms.file')"
                        label-for="h-closed"
                        label-cols-md="2"
                        content-cols-lg="4"
                    >
                        <BootstrapIcon v-if="fileURL" icon="eye" size="1x"/>
                        <a v-if="fileURL" :href="fileURL" target="_blank">
                            {{ $t('forms.viewFile') }}
                        </a>
                        <document-upload v-if="$helper.userCapability('update',$route)" :simple="true"
                                         @fileUploaded="setFile"/>
                    </b-form-group>
                    <b-form-group
                        v-if="'id' in receipt && !isOwner()"
                        class="row"
                        :label="$t('forms.closed')"
                        label-for="h-closed"
                        label-cols-md="2"
                        content-cols-lg="4"
                    >
                        <b-form-checkbox v-model="receipt.closed" id="h-closed" switch/>
                    </b-form-group>
                </b-col>
            </b-row>
        </b-form>
    </div>
</template>
<script>

import useVuelidate from "@vuelidate/core";
import {required} from "@vuelidate/validators";
import ResourceUtils from "@/mixins/ResourceUtils";
import ListUtils from "@/mixins/ListUtils";
import Datepicker from "@vuepic/vue-datepicker";
import "@vuepic/vue-datepicker/dist/main.css";
import vSelect from "vue-select";
import {getUserData} from "@/auth/utils";
import DocumentUpload from "@/components/DocumentUpload.vue";

export default {
    components: {
        Datepicker,
        vSelect,
        DocumentUpload
    },
    props: {
        receiptObject: {Object, required: true},
        action: {type: String, default: null},
    },
    mixins: [ResourceUtils, ListUtils],
    created() {
        this.getEnumList(["receipt_status", "receipt_category", "currency"], this.statuses, this.categories, this.currencies);
        this.getResourceList(this.$Branches, this.branches)
        this.getResourceList(this.$Stations, this.stations)
        if ("id" in this.receipt) {
            if (this.receipt.branch) {
                this.branchSelect = {
                    label: this.receipt.branch.name,
                    id: this.receipt.branch['@id'],
                };
            }
            if (this.receipt.station) {
                this.stationSelect = {
                    label: this.receipt.station.name,
                    id: this.receipt.station['@id'],
                };
            }
            this.statusSelect = {
                label: this.$helper.getEnumTranslation("receipt_status", this.receipt.status, this.$i18n.locale),
                id: this.receipt.status,
            };
            this.categorySelect = {
                label: this.$helper.getEnumTranslation("receipt_category", this.receipt.category, this.$i18n.locale),
                id: this.receipt.category,
            };
            this.currencySelect = {
                label: this.$helper.getEnumTranslation("currency", this.receipt.currency, this.$i18n.locale),
                id: this.receipt.currency,
            };
        }
        if (this.receipt.url) {
            this.fileURL = this.receipt.url;
        }
    },
    setup: () => ({v$: useVuelidate()}),
    validations() {
        return {
            stationSelect: {required},
            categorySelect: {required},
            currencySelect: {required},
            branchSelect: {required},
            receipt: {
                date: {required},
                amount: {required}
            },
        };
    },
    data() {
        return {
            receipt: this.receiptObject,
            statuses: [],
            categories: [],
            currencies: [],
            branches: [],
            stations: [],
            statusSelect: {},
            branchSelect: {},
            stationSelect: {},
            categorySelect: {},
            currencySelect: {},
            closed: false,
            fileURL: null,
        };
    },
    watch: {
        action() {
            this.onSubmit();
        },
    },
    methods: {
        setFile(data) {
            if ('file' in this.receipt) {
                this.deleteByUrl(this.$Files, this.receipt.file, null);
            }
            this.receipt.file = data["@id"];
            this.fileURL = data.url;
        },
        async onSubmit() {
            const isValid = await this.v$.$validate();
            if (isValid) {
                this.receipt.station = this.stationSelect.id;
                this.receipt.category = this.categorySelect.id;
                this.receipt.currency = this.currencySelect.id;
                this.receipt.branch = this.branchSelect.id;
                if (this.action === "add") {
                    this.create(
                        this.$Receipts,
                        this.receipt,
                        this.$t("messages.receiptCreated"),
                        null,
                        this.success,
                        this.error
                    );
                }
                if (this.action === "update") {
                    this.receipt.status = this.statusSelect.id;
                    if (this.receipt.closed) {
                        this.receipt.closedBy = getUserData()["@id"];
                    }
                    this.$emit("clearAction");
                    this.update(
                        this.$Receipts,
                        this.receipt.id,
                        this.receipt,
                        this.$t("messages.receiptUpdated"),
                        null,
                        this.success,
                        this.error
                    );
                }
            } else {
                this.$emit("clearAction");
            }
        },
        isOwner() {
            return "employee" in this.receipt &&
                getUserData().id === this.receipt.employee.id;
        },
        success() {
            this.$router.push(`/receipts`);
        },
        error() {
            this.$emit("clearAction");
        },
    },
};
</script>

<style lang="scss">
@import "vue-select/src/scss/vue-select.scss";
</style>

